import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const Box = makeShortcode("Box");
const SingleImage = makeShortcode("SingleImage");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "the-experience-of-grief"
    }}>{`The experience of grief`}</h2>
    <p>{`Grief is a normal and necessary process. It can be something one person goes through on their own, or a shared experience (e.g. shared with family members). Each young person will express their grief differently. How the death of a loved one affects a teen will depend on their age, relationship to the person who died, how unexpected the death was, family situation, and their personality and coping skills.`}</p>
    <p>{`So what are the signs of grief in teenagers?`}</p>
    <Typography color="primary" mdxType="Typography">
  Click the boxes below to learn more. Note that most teens won’t experience all of these at once.
    </Typography>
    <br />
    <Grid container spacing={3} justify="center" mdxType="Grid">
  <Grid item xs={12} sm={12} mdxType="Grid">
    <TextReveal id="textRevealEmotionalPsychological" header="Emotional & psychological (click to reveal)" mountOnEnter={true} mdxType="TextReveal">
      <ul>
        <li>Strong emotions, including sadness, anger, anxiety, fear, guilt</li>
        <li>Shock or disbelief about the death</li>
        <li>Difficulty concentrating or remembering things</li>
        <li>Preoccupation with the death </li>
        <li>
          Worrying about a parent or other family member dying. This can include becoming anxious when apart from
          parents (i.e. separation anxiety), or wanting to stay physically close to parents/family members
        </li>
      </ul>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealBehaviouralSocial" header="Behavioural & social (click to reveal)" mountOnEnter={true} mdxType="TextReveal">
      <ul>
        <li>Changes in behaviour, e.g. disruptive or non-compliant behaviour, ‘acting out’, risk taking</li>
        <li>Acting with bravado, as though they are not affected by the death</li>
        <li>Seeking reassurance, e.g. checking in on parents or siblings to see if they are okay</li>
        <li>
          Needing more attention than usual from adults around them <b>or</b> withdrawing from adults/family
        </li>
        <li>Reluctance to go to school</li>
      </ul>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <Box mdxType="Box">
      <TextReveal id="textRevealPhysical" header="Physical (click to reveal)" mountOnEnter={true} mdxType="TextReveal">
        <ul>
          <li>Headaches, stomach aches, body aches and pains</li>
          <li>Tiredness</li>
          <li>Changes in sleep and appetite</li>
        </ul>
      </TextReveal>
      <SingleImage smallGridSize={8} gridSize={6} src={'/images/m8/41-01.svg'} alt="Grieving child" mdxType="SingleImage" />
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} mdxType="Grid">
    <ButtonDialog buttonFullWidth title="If a teen was unable to see their loved one before they died" linkText="What if a teen was unable to see their loved one before they died?" mdxType="ButtonDialog">
      <Typography mdxType="Typography">
        Being unable to see a loved one before they die can make it harder for a teen to accept what has happened. They
        might feel that something is unfinished or unresolved, or like the death is not real.
        <br />
        <br />
        They might also feel guilty for not being there, or worry about whether their loved one was okay before they died.
        <br />
        <br />
        These reactions are all normal and expected. It is okay if it takes your teen some time to accept what has
        happened.
      </Typography>
    </ButtonDialog>
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      